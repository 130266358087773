<template>
	<span>
		<LoadingImg v-if="loading.list" />
		<div class="ol-dashboard__address">
			<h4 class="ol-dashboard__container-title">Dropshipper</h4>
			<a v-on:click="showPopup(true)" class="ol-btn ol-dashboard__address-add">+ Tambah Alias</a>
			
			<div class="ol-checkout-content__shipping ol-dashboard__address-shipping ol-dashboard__address-bank" style="margin-top:30px">
				<div class="ol-checkout-content__shipping-list">
					<Alert v-show="alertList.message !=null " :type="alertList.type" :message="alertList.message"/>
                    <table class="ol-table-responsive">
                        <thead>
                            <tr>
                                <th>Nama</th>
                                <th>Telepon</th>
                                <th>Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in dropshippers" :key="item.id">
                                <td>{{item.name}}</td>
                                <td>{{item.phone}}</td>
                                <td align="center">
                                    <a title="Edit" v-on:click="edit(item.id)"><i class="far fa-edit"></i></a>
									<a class="ol-del" v-on:click="del(item.id)"><i class="fas fa-trash"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
					
				</div>
                <a href="/checkout" class="ol-btn ol-btn--secondary">Checkout</a>
			</div>
            
		</div>
		<Popup :title="title">
			<LoadingImg v-if="loading.save" />
			<div>
                <Alert v-show="validateBackend !=null " type="danger" :collection="validateBackend"/>

				<div class="ol-input-box ol-input-box--full">
					<label class="ol-input-label">Nama Lengkap</label>
					<input type="text" class="ol-input" v-model="input.name">
				</div>
				<div class="ol-input-box ol-input-box--full">
					<label class="ol-input-label">Telepon</label>
					<input type="number" class="ol-input" v-model="input.phone">
				</div>

				<div class="ol-input-box ol-input-box--half">
					<button type="button" name="" v-on:click="save()" class="ol-btn">Simpan</button>
				</div>
			</div>
		</Popup>
	</span>
</template>

<script>
import { mapGetters,mapActions } from 'vuex'
import LoadingImg from '../../components/loading'
import Popup from '@/components/popup/Popup.vue'
import select2 from '@/lib/select2'
import globalUrl from '@/util/globallUrl'
const UNI = globalUrl.WS_UNI;
const BASE = process.env.VUE_APP_BASE_API_URL+"/";

export default {
    name:"DashboardDropshipper",
    components:{
		LoadingImg,
		Popup,
    },
	data(){
        return{
            title: null,
            input:{
				id:null,
                name: null,
				dropshipper: null,
				province: null,
				city: null,
				district: null,
				village: null,
				postal_code: null,
				phone: null,
				default: 0,
            },
			loading:{
                list:true,
                save:false,
            },
			validateBackend:null,
            alertList:{
				message:null,
				type:"info",
			},
        }
    },
	computed:{
        ...mapGetters('profile/dropshipper', ['dropshippers']),
    },
    methods:{
        ...mapActions('profile/dropshipper', ['fetchDropshippers','saveDropshipper','updateDropshipper','delDropshipper']),

        showPopup(show=true,isNew=true){  
            this.title="Edit Dropshipper";
            if(isNew){
                this.title="Tambah Dropshipper";
				this.input.id= null;
				this.input.name= null;
				this.input.phone= null;		
            }
            (show)? Popup.methods.show():Popup.methods.hide();
			this.validateBackend = null;
            this.loading.save = false;
        },
        
        async save(){
			this.alertList.message=null;
            this.validateBackend=null;
			this.loading.save = true;
			const result = (!this.input.id) ? await this.saveDropshipper(this.input) : await this.updateDropshipper(this.input);
			if(result.success){
				this.showPopup(false)  
				this.alertList.message = "Berhasil menyimpan Dropshipper";
				this.alertList.type="success";
                this.loading.list = false;  
			}else{
				this.validateBackend=result.data.message ? {address:[" Gagal menyimpan Dropshipper, coba beberapa saat lagi"]}:result.data;
				this.loading.save = false;
				// this.showPopup(false)  
			}
        },
        edit(id){
            const dropshipper = _.find(this.dropshippers,{"id":id});
        	this.input.id = dropshipper.id;
        	this.input.name = dropshipper.name;
			this.input.phone = dropshipper.phone;
            this.showPopup(true,false);
        },
        del(id){
			this.alertList.message=null;
            Swal.fire({
                text: "Yakin ingin dihapus?",
                icon: 'warning',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: '#364b7b',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Hapus',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.loading.list = true;
                    const result = await this.delDropshipper(id);
                    if(result.success){
						this.message =  "Berhasil menghapus Dropshipper";
						this.alertList.message = "Berhasil menghapus Dropshipper";
						this.alertList.type="success";
                        this.loading.list = false;  
                    }else{
						this.alertList.message = result.data.message? result.data.message:"Gagal menghapus alamat, coba beberapa saat lagi";
						this.alertList.type="danger";
                        this.loading.list = false;  
                    }

                }
            })
        }
    },
    async mounted(){
        await this.fetchDropshippers() ? this.loading.list=false : this.$toast.open({ message: "Gagal mendapatkan data alamat", type: "error", duration: 3000, });
        // await this.fetchProvinces() ? this.loading.list=false : this.$toast.open({ message: "Gagal mendapatkan data provinsi", type: "error", duration: 3000, });
    }

}
</script>

<style>
table {
  border-collapse: collapse;
  width:100%;
}

table, th, td {
  border: 1px solid #D2D2D2;
  padding:4px;

}
</style>